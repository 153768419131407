<template>
  <div class="signup-container">
    <Loading v-if="isLoading" />
    <div v-if="!isLoading" class="signup">
      <img
          src="@/assets/images/brains-logo.svg"
          alt="Brains logo"
          class="signup__logo"
      />
      <div class="container signup__content">
        <h1 class="container__tittle">Create an account</h1>
        <div class="form container__form">
          <input class="form__input" id="email" v-model="email"
          autocomplete="off" placeholder="Work email address" @keyup.enter="checkForm"/>
          <span class="form__error" v-if="msg.email">{{msg.email}}</span>
          <button class="form__button" :disabled="!formCorrect"
          @click="checkForm()">Sign Up</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";

export default {
  name: 'Signup',
  components: { Loading },
  data() {
    return {
      email: '',
      msg: [],
      formCorrect: false,
      isLoading: false,
    };
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
  },
  methods: {
    resetTokenStorage() {
      localStorage.setItem('brains-register-token', '');
    },
    validateEmail(value) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg.email = '';
        this.formCorrect = true;
      } else {
        this.msg.email = 'Invalid Email Address';
        this.formCorrect = false;
      }
    },
    async sendEmail(email) {
      this.isLoading = true;
      const response = await this.$store.dispatch('freelancer/sendEmailToken', email);
      if (response.length === 0) {
        this.resetTokenStorage();
        localStorage.setItem('brains-register-email', this.email);
        this.$router.push({ path: '/verify-email' });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: response,
          detail: 'Error sending confirmation email',
          life: 4000,
        });
      }
      this.isLoading = false;
    },
    checkForm() {
      if (this.formCorrect) {
        this.sendEmail(this.email);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-container {
  min-height: 100vh;
  display: flex;
}

.signup {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
  &__logo {
    width: 200px;
    margin: 0 3rem 3rem 3rem;
  }
  &__content {
    display: grid;
    justify-items: center;
    margin: 0 1rem;
    padding-bottom: 3rem;
  }
}
.container {
    &__tittle {
        font-size: 1.5rem;
    }
    &__form {
    display: grid;
    justify-items: center;
    margin: 0 1rem;
  }
}
.form {
  &__button {
    @include primary-button();
    min-width: 20rem;
    margin-top: 1rem;
  }
  &__input-label {
    margin: 0 0 0 0.5rem;
  }
  &__input {
    @include input(100%);
    min-width: 20rem;
    margin-bottom: .5rem;
  }
  &__error {
    color: $alert-color;
    font-size: 12px;
  }
}
</style>
